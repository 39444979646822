import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PME = () => {
    return (
        <Layout>
            <Seo
                title="Petites et Moyennes Entreprises - BIKESAFE"
                description="Avec la hausse des prix du carburant et la volonté de réduire les émissions nocives, le vélo urbain est en plein développement..."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Petites et Moyennes Entreprises
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Optez pour un environnement plus vert !</h3>
                        <p className="flow-text">
                            Avec la hausse des prix du carburant et la volonté de réduire les émissions nocives, le vélo urbain est en plein développement : de plus en plus de gens privilégient le vélo pour leurs déplacements quotidiens. Par conséquent, mettre en place une solution de rangement / stationnement pour vélos qui soit pratique à disposition des clients ou des employés est une décision d'entreprise judicieuse qui peut potentiellement accroître votre activité.
                        </p>
                        <p className="flow-text">
                            Les adeptes du vélo urbain, les cyclistes et non cyclistes apprécieront votre approche écologique vis-à-vis de votre installation pour un stationnement sécurisé des vélos. Le fait de pouvoir se rendre facilement à vélo à votre magasin ou entreprise permettra sans aucun doute de fidéliser votre clientèle.
                        </p>
                        <p className="flow-text">
                            Une solution de rangement / stationnement pour vélos clairement destinée à cet effet évite les conflits avec les piétons et les véhicules stationnés. Elle permet aux cyclistes de ne pas accrocher leur vélos aux grilles et poteaux indicateurs afin d'éviter de gêner l'accès aux magasins et la circulation des piétons.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/pme.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">« Le nouvel aménagement est très efficace. Merci pour votre aide. »
                                <strong> Simon Flack, Cycleops (propriétaire de magasin), Kent</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PME;